<script lang="ts" setup>
import LoginRight from '@/components/UI/images/LoginRight.vue'
import Logo from '@/components/UI/images/Logo.vue'
import useSessionStore from '~/store/session'
import * as Sentry from '@sentry/browser'

const $i18n = useI18n()
const $route = useRoute()
const sessionStore = useSessionStore()

onMounted(() => {
    $i18n.setLocale(($route.query.lang as string) || 'fr')

    // @ts-ignore
    window?.FrontChat?.('init', {
        chatId: '03c4485d687f078c1f56a074c43c9766',
        useDefaultLauncher: true
    })

    if (useRuntimeConfig().public.ENABLE_JIMO) {
        try {
            const windowHDLD = window as any

            if (!windowHDLD.jimo) {
                windowHDLD.jimo = []

                const s = document.createElement('script')

                s.type = 'text/javascript'
                s.async = true
                s.src = 'https://undercity.usejimo.com/jimo-invader.js'

                // Clé en dur car elle ne sert pour l'instant qu'à des tests pour l'équipe produit
                windowHDLD.JIMO_PROJECT_ID =
                    'c813fc1c-63ca-4046-a340-9e070bce3423'
                document.getElementsByTagName('head')[0]?.appendChild(s)

                windowHDLD.jimo.push([
                    'set',
                    'user:email',
                    [sessionStore.user?.email]
                ])
                windowHDLD.jimo.push([
                    'do',
                    'identify',
                    [
                        sessionStore.user?.id.toString(),
                        () => {
                            windowHDLD.jimo.push([
                                'set',
                                'user:name',
                                [sessionStore.user?.fullName]
                            ])
                        }
                    ]
                ])

                windowHDLD.jimo.push([
                    'set',
                    'user:attributes',
                    [{ foo: 'hello', bar: 'world' }, true]
                ])
            }
        } catch (e) {
            Sentry.captureMessage('Jimo error')
        }
    }
})
</script>

<template>
    <div
        class="min-h-screen p-4 lg:p-9 flex h-full w-full items-stretch gap-28 justify-center"
    >
        <div
            class="w-full flex flex-col items-center justify-center lg:px-16 gap-6 lg:gap-9"
        >
            <Logo />
            <slot />
        </div>
        <div
            class="hidden md:flex w-full items-center justify-center rounded-2xl px-11 bg-primary-400"
        >
            <LoginRight class="hidden md:block h-4/5" />
        </div>
    </div>
</template>
